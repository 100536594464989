/* footer {
  margin-top: 10vmax;
  padding: 2vmax;
  background-color: rgb(34, 33, 33);
  color: white;
  display: flex;
  align-items: center;
}

@media (max-width: 786px) {
  footer {
    flex-direction: column;
    gap: 2.5rem;
  }
}

.leftFooter {
  width: 20%;
  display: flex;

  flex-direction: column;
  align-items: center;
}
.leftFooter > h4 {
  font-family: "Roboto";
  font-size: 1vmax;
}
.leftFooter > p {
  text-align: center;
  font-size: 1.2vmax;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.leftFooter > img {
  width: 10vmax;
  margin: 1vmax;
  cursor: pointer;
}

.midFooter {
  width: 60%;
  text-align: center;
}

.midFooter > h1 {
  font-size: 4vmax;
  font-family: "Roboto";
  color:crimson;
}

.midFooter > p {
  max-width: 60%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 1vmax auto;
}

.rightFooter {
  width: 20%;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.rightFooter > h4 {
  font-family: "Roboto";
  font-size: 1.4vmax;
  text-decoration: underline;
}
.rightFooter > a {
  text-decoration: none;
  font-size: 1.3vmax;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: white;
  transition: all 0.5s;
  margin: 0.5vmax;
}

.rightFooter > a:hover {
  color: #eb4034;
}



 */















 footer {
  /* min-height: 100px; */
  min-height: 100px;
  /* background-color: $color6; */
  background-color: #161616;
  /* color: $color4; */
  color: #fff;
  /* padding: 6rem 0; */
  padding: 2rem 0;
  padding-bottom: 0;
  /* padding-left: $p; */
  padding-left: 13.33vmax;
  display: grid;
  grid-template-columns: 10fr 3fr 1fr;
  align-items: center;
  justify-items: center;
  text-align: center;
}

footer > div {
  width: 100%;
  /* border-left: 2px solid $color4; */
  /* border-left: 2px solid #fff; */
  /* border-right: 2px solid $color4; */
  border-right: 2px solid #fff;
}

footer > div > iframe {
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  object-fit: contain;
}

footer > div > h2 {
  margin: 1rem;
}

footer > div > p {
  /* font: 400 1.2rem $text2; */
  font: 400 1.2rem "Open Sans", sans-serif;
}

footer > aside > article {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  /* flex-direction: row; */
  /* align-items: center; */
}

footer > aside > article > a {
  /* color: #fff; */
  text-decoration: none;
}

footer > aside > article > a:hover {
  transform: scale(1.2, 1.2);
}

.insta {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  color: #fff;
}

.facebook {
  color: #1877F2;
}

.whatsapp {
  color: #25D366;
}



/* .insta {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 40px;
  /* color: #fff; */
  /* font-size: 220px; */
  /* line-height: 250px; */
  /* align-self: middle ;
  vertical-align: middle; */
  /* background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25); */
/* } */
/* svg {
        font-size: 2rem;
        color: $color4;
        &:hover {
          color: $color3;
        }
      }
    } */

footer > a {
  width: 50px;
  height: 50px;
  /* background-color: $color4; */
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

footer > a > svg {
  font-size: 2rem;
  /* color: $color3; */
  color: #f26440;
}

footer > a:hover {
transform: scale(1.2, 1.2);
}



footer > aside > article > a {
  background-color: #fff;
  border-radius: 50%;
  /* color: #f26440; */
  /* background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%); */
  /* align-self: center; */
  /* justify-content: center; */
  /* flex-direction: column; */
  place-items: center;
  /* font-size: 2rem; */
  display: grid;
  width: 50px;
  height: 50px;
  margin: 4px;
  /* margin-top: -10px; */
}

footer >  aside > article > a > svg {
  font-size: 2rem;
  /* color: $color3; */
  /* color: #f26440; */
  /* background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%); */
}

.footerSupport {
  background-color: #161616;
  color: #fff;
  padding: 2.5vmax 5rem 0.5vmax;
  display: flex;
  gap: 1rem;
  /* justify-content: space-between; */
  justify-content: center;
}

.footerSupport a {
  color: #fff;
  text-decoration: none;
  color: white;
  margin-left: 2vmax;
}

.footerSupport > p{
  justify-content: end;
}

@media screen and (max-width: 1367px) {
  footer {
    /* padding-left: $p_md; */
    padding-left: 7vmax;
  }
}

@media screen and (max-width: 900px) {
  footer {
    /* padding-left: $p_zero; */
    padding-left: 0;
    grid-template-columns: 1fr;
  }

  footer > div {
    width: 100%;
    border: none;
  }

  footer > div > p {
    width: 50%;
    margin: 2rem auto;
  }

  footer > a {
    margin: 1rem;
  }

  .footerSupport {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 786px) {
}

