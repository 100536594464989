.aboutSection {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: 4fr 4fr;
  position: fixed;
}

.aboutSectionGradient {
  background-image: linear-gradient(
    to bottom right,
    rgb(78, 81, 255),
    rgb(74, 137, 189)
  );
}

.aboutSectionContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: rgb(255, 255, 255);
  width: 80vw;
  height: 60vh;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.192);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutSectionContainer > h1 {
  font: 400 20px "Roboto";
  color: tomato;
  /* margin: 2vmax; */
  margin-top: 0;
}

.aboutSectionContainer > div {
  display: flex;
  width: 100%;
}

.aboutSectionContainer > div > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-items: justify;
  padding: 2vmax;
  box-sizing: border-box;
}

.aboutSectionContainer > div > div > ul {
  color: #2E3233;
  font: 500 14px "georgia";
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 600px) {
.aboutSectionContainer {
  font: 500 12px "georgia";
  height: 90vh;
}
}  

.aboutSectionContainer > div > div > ul >li{
  margin-top: 5px;
}

.aboutSectionContainer > div > div > button {
  margin: 1vmax 0;
}

/* .aboutSectionContainer > div > div > span { */
.aboutSectionContainer > div > div > h1 {
  /* font: 900 2vmax "Roboto";
  color: rgba(0, 0, 0, 0.616);
  /* text-align: center; */
  /* text-align: start;
  width: 80%;
  padding-left: 20px ; */ 
  
  font: bolder 24px "georgia";
  text-align: center;
  word-spacing: 4px;
  letter-spacing: 1.4px;
  width: fit-content;
  border-bottom: 1px solid #161616;
  /* padding: 0.5rem; */
  margin: auto;
}

.aboutSectionContainer2 {
  border-left: 1px solid rgba(0, 0, 0, 0.116);
}

.aboutSectionContainer2 > h2 {
  color: rgba(0, 0, 0, 0.623);
  font: 100 2vmax "Roboto";
  margin: 2vmax;
}

.aboutSectionContainer2 > a > svg {
  font-size: 4vmax;
}

.youtubeSvgIcon {
  color: tomato;
}

.instagramSvgIcon {
  color: rgb(144, 81, 202);
}

@media screen and (max-width: 600px) {
  .aboutSectionContainer > div {
    display: block;
  }

  .aboutSectionContainer > h1 {
    font: 400 5vmax "Roboto";
    margin: 3vmax;
  }

  .aboutSectionContainer > div > div > p {
    font: 100 2vmax "Roboto";
  }

  .aboutSectionContainer > div > div > span {
    font: 100 1.4vmax "Roboto";
    width: 70%;
  }

  .aboutSectionContainer2 {
    border-left: none;
  }

  .aboutSectionContainer2 > h2 {
    font: 100 3vmax "Roboto";
    margin: 2vmax;
  }

  .aboutSectionContainer2 > a > svg {
    font-size: 6vmax;
    margin: 1vmax;
  }
}
