/* .speedDial2 {
  position: fixed;
  left: 3vmax;
  top: 9vmax;
}

.speedDialIcon2 {
  width: 56px;
  height: 56px;
  border-radius: 100%;
}

.speedDial {
  position: fixed;
  right: 3vmax;
  top: 9vmax;
}

.speedDialIcon {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: #fff 0.5rem solid;
}



nav {
  width: 100%;
  height: 100px;
  background-color: #fef2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0%;
  z-index: 60;
}

nav > h2 {
  font-size: 2rem;
}

nav > div {
  display: flex;
  align-items: center;
  gap: 2rem;
}

nav > div > a {
  font-size: 2vmax;
  color: #161616;
  font-weight: 500;
  transition: all 0.3s;
  letter-spacing: 1px;
  text-decoration: none;
}

nav > div > a:hover {
  color: #f26440;
}

nav > a > button {
  border: 1px solid #16161698;
  padding: 0.5rem 2rem;
  background-color: #fef2f2;
  cursor: pointer;
  color: #161616;
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 0.3s;
}

nav > a > button:hover {
  background-color: #161616;
  color: #fff;
}

.navBtn {
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  color: #292b46;
  font-size: 1.4rem;
  z-index: 150;
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: none;
}

.navBtn:hover {
  color: #f26440;
}

.navPhone {
  transition: all 0.5s;
  transform: translateY(-200%);
  width: 100%;
  height: 100vh;
  background-color: #292b46;
  padding: 5vmax;
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.navPhone > h2 {
  font-size: 2rem;
  color: #fff;
}

.navPhone > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.navPhone > div > a {
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 0.3s;
}

.navPhone > div > a:hover {
  color: #f26440;
}

.navPhone > a > button {
  border: 1px solid #16161698;
  padding: 0.5rem 2rem;
  background-color: #fef2f2;
  cursor: pointer;
  color: #161616;
  letter-spacing: 1px;
  font-weight: 500;
  transition: 0.5s;
}

.navPhone > a > button:hover {
  background-color: #161616;
  color: #fff;
}

.navBtn {
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  color: #292b46;
  font-size: 1.4rem;
  display: none;
  z-index: 150;
  position: fixed;
  top: 2rem;
  right: 2rem;
}

.navBtn:hover {
  color: #f26440;
}

.navPhoneComes {
  transform: translateY(0);
}

@media screen and (max-width: 786px) {
  
  .speedDial {
    position: fixed;
    right: 3vmax;
    top: 15vmax;
  }
  
  .speedDialIcon {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: #fff 0.5rem solid;
  }

  nav {
    flex-direction: column;
  }
  
  nav > div > a {
  font-size: 12px;
  } 

}

@media screen and (max-width: 600px) {

  .speedDial {
    position: fixed;
    right: 3vmax;
    top: 20vmax;
  }

  .speedDialIcon {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: #fff 0.5rem solid;
  }

  nav {
    padding: 0 0;
    flex-direction: column;
  }

    nav > h2 {
      font-size: 1.8rem;
    }
    nav > div {
      gap: 1rem;
    }
  }

   */

.speedDial {
  position: fixed;
  right: 3vmax;
  top: 10vmax;
}

.speedDialIcon {
  width: 56px;
  height: 56px;
  border-radius: 100%;
}

nav {
  width: 100%;
  height: 80px;
  background-color: #fef2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* justify-content: space-around; */
  padding: 0 0;
  position: sticky;
  top: 0%;
  z-index: 60;
  text-decoration: none;
  /* margin-left: 10px; */
}

nav > h2 {
  font-size: 2rem;
}

nav > div {
  display: flex;
  align-items: center;
  gap: 2rem;
}

nav > div > img {
  margin-left: 20px;
}

nav > div > a {
  color: #2B2E32;
  font-weight: 900;
  transition: all 0.3s;
  letter-spacing: 1px;
  /* font-size: 2rem; */
  margin-right: 10px;
  text-decoration: none;
  font: bold 18px 'karla';
  text-transform: uppercase;
}

nav > div > a:hover {
  color: #f26440;
  border-top: 3px solid #f26440;
  padding-top: 30px;
}

nav > a > button {
  border: 1px solid #16161698;
  padding: 0.5rem 2rem;
  background-color: #fef2f2;
  cursor: pointer;
  color: #161616;
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 0.3s;
}

nav > a > button:hover {
  background-color: #161616;
  color: #fff;
}

.navBtn {
  width: 40px;
  height: 40px;
  border: none;
  border-color: transparent;
  color: #292b46;
  font-size: 1.4rem;
  display: none;
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 150;
}

.navBtn:hover {
  color: #f26440;
}

.navPhone {
  transition: all 0.5s;
  transform: translate(200%, -200%);
  width: 100%;
  height: 100vh;
  background-color: #292b46;
  font-size: 1.5rem;
  position: fixed;
  top: 0;
  z-index: 100;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.navPhone > h2 {
  font-size: 2rem;
  color: #fff;
}

.navPhone > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 2rem;
}

.navPhone > div > a {
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 0.3s;
  text-decoration: none;
}

.navPhone > div > a:hover {
  color: #f26440;
}

.navPhone > a > button {
  border: 1px solid #16161698;
  padding: 0.5rem 2rem;
  background-color: #fef2f2;
  cursor: pointer;
  color: #161616;
  letter-spacing: 1px;
  font-weight: 500;
  transition: 0.5s;
}

.navPhone > a > button:hover {
  background-color: #161616;
  color: #fff;
}

.navPhoneComes {
  transform: translateY(0);
}

@media screen and (max-width: 768px) {
  nav > div {
    display: none;
  }

  nav > a {
    display: none;
  }

  .navBtn {
    display: block;
  }

  .speedDial {
    position: fixed;
    right: 3vmax;
    top: 15vmax;
  }

  .speedDialIcon {
    width: 56px;
    height: 56px;
    border-radius: 100%;
  }
}

@media screen and (max-width: 900px) {
  nav > h2 {
    font-size: 1.8rem;
  }

  nav > div {
    gap: 1rem;
  }
}
