

.banner {
  background-image: url("../../images/Main_banner.jpeg");
  background-position: center;
  /* background-position: top; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vmin;
  /* height: 600px; */
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  /* justify-content: center; */
  justify-content: center;
  color: black;
}

.banner > h1 {
  margin: 5vmax;
  font: 600 2.5vmax "Roboto";
  background-color: #fff;
  border-radius: 15px;
  padding: 5px;
}


.banner > p {
  font: 300 1.8vmax "Lucida Sans";
  background-color: #fff;
  border-radius: 15px;
  padding: 5px;
}

.banner > a > button {
  margin-bottom: 5vmax;
  cursor: pointer;
  background-color: white;
  border: 1px solid white;
  border-radius: 0;
  padding: 1vmax;
  transition: all 0.5s;
  width: 9vmax;
  font: 500 1vmax "Roboto";
}
.banner > a > button:hover {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.banner::after {
  content: "";
  width: 100vw;
  height: 120vmin;
  background-color: #ffffff;
  position: absolute;
  /* position: relative; */
  top: 17.5%;
  left: 0;
  /* clip-path: polygon(100% 68%, 0 100%, 100% 100%); */
  clip-path: polygon(100% 100%, 0 100%, 100% 100%);
  max-width: 100%;
}

.homeHeading {
  text-align: center;
  font-family: Roboto;
  /* font-size: 1.4vmax; */
  font-size: 2.4vmax;
  /* border-bottom: 1px solid rgba(21, 21, 21, 0.5); */
  border-bottom: 1px solid black;
  width: 20vmax;
  padding: 1vmax;
  /* margin: 5vmax auto; */
  margin: 2vmax auto;
  /* color: rgb(0, 0, 0, 0.7); */
  color: black;
}

.container {
  display: flex;
  margin: 2vmax auto;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  /* height: 50vmin; */
  /* height: 175vmin; */
  height: auto;
}

/* original */
/* .productCard {
  width: 14vmax;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  margin: 2vmax;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
} */




.productCard > img {
  width: 14vmax;
}

.productCard > div {
  margin: 0.5vmax;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.productCardSpan {
  margin: 0.5vmax;
  font: 300 0.7vmax "Roboto";
}

.productCard > p {
  font-family: "Roboto";
  font-size: 1.2vmax;
  margin: 1vmax 0.5vmax;
  margin-bottom: 0;
}

.productCard > span {
  margin: 0.5vmax;
  color: tomato;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1vmax;
}

.productCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);

  transform: translateY(-1vmax);
}

/* .rating_reviews {
  width: 10px;
} */



@media screen and (max-width: 600px) {

  .container {
    height: 250vmin;
  }



  .productCard > p {
    font-size: 1.7vmax;
  }

  .productCard > div {
    margin: 0vmax;
    display: block;
  }

  .productCard > span {
    font-size: 1.5vmax;
  }

  .productCard > div > span {
    margin: 0 0.5vmax;
    font: 300 1vmax "Roboto";
  }

  .banner {
    height: 80vmin;
  }
  
  .banner > h1 {
    margin: 3vmax;
    font: 600 2vmax "Roboto";
  }
  
  .banner > p {
    font: 300 2vmax "Lucida Sans";
  }

  
.banner > a > button {
  margin-bottom: 5vmax;
  border-radius: 10px;
  width: 19vmax;
  font: 500 2vmax "Roboto";
}

.homeHeading {
  text-align: center;
  font-family: Roboto;
  font-size: 3vmax;
  border-bottom: 1px solid rgba(21, 21, 21, 0.5);
  /* width: 60vmax; */
  width: 30vmax;
  padding: 1vmax;
  margin: 5vmax auto;
  color: rgb(0, 0, 0, 0.7);
}

}


.tag {
  background-image: url("../../images/DrkTealBG.jpg");
  /* margin: auto; */
  border-bottom: solid 3px black;
}

.tag > p {
  color: #ffffff;
  padding: 30px 0 30px 0;
  font: 700 32px "georgia";
  /* font-size: 32px; */
  text-align: center;
  word-spacing: 4px;
  letter-spacing: 1.4px;
}

.philosophy{
  margin-top: 60px;
}

.philosophy > h4{
  font: bolder 24px "georgia";
  text-align: center;
  word-spacing: 4px;
  letter-spacing: 1.4px;
  width: fit-content;
  border-bottom: 1px solid #161616;
  padding: 0.5rem;
  margin: auto;
} 

.philosophy > p{
  font: 400 16px "PT Serif";
  color: #2a2a2a;
  text-align: justify;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;
}



.Why-out > h4{
  font: 900 24px "georgia";
  text-align: center;
  word-spacing: 4px;
  letter-spacing: 1.4px;
  width: fit-content;
  border-bottom: 1px solid #161616;
  padding: 0.5rem;
  margin: auto;
} 

.why {
  display:flex;
  margin-top: 30px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .why {
    display:grid;
    margin-top: 30px;
    width: 100%;
    justify-content: center;
  }

  .Why-out > .why > .image {
    width: 100%;
  }

  .Why-out > .why > .image > img {
    width: 80vw;
    height: 300px;
    align-content:space-evenly;
    margin-left: 30px;
  }

  .Why-out > .why > .accord {
    width: 80%;
    margin: auto;
  }
}

.image {
  width: 50%;
}

.image > img {
  width: 80%;
  height: 300px;
  margin: 30px;
  margin-left: 70px;
  align-self: left;
  border-radius: 20px;
  border: 3px solid #151515;
}

.accord {
  width: 50%;
  margin-right: 60px;
}

.accordion{
  border: none;
}

.accordion.header {
  color: #e6f3ff;
}

.accordion-header button{
  background-color: #e6f3ff;
  color: #3a3a3a;
  font: 18px "pt-sans";
  margin-bottom: 30px;
}

.accordion-header button:hover{
  color: #00a4ef;
}

.accordion-body {
  color: #1c1a1a;
  font: 16px 'pt-serif';
}


.grid {
  margin: 0;
  /* background-color: rgb(37, 37, 37); */
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  padding: 70px;
  /* font: 900 24px "georgia";
  text-align: center;
  word-spacing: 4px;
  letter-spacing: 1.4px; */
}

@media screen and (max-width: 500px) {
  .grid {
    width: 100%;
    padding: 20px;
    grid-gap: 10px;
    margin: auto;
  }
}

@media screen and (max-width: 300px) {
  .grid {
    width: 100%;
    padding: 20px;
    grid-gap: 10px;
    margin: auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.box, .box1, .box2, .box3, .box4, .box5, .box6, .box7 {
  /* width: 100px; */
  height: 200px;
  /* background-color: rgb(0, 132, 255); */
  color: white;
  /* border: 3px solid red; */
  /* font: 100 2rem cursive; */
  text-align:center;
  align-content:end;
  border-radius: 20px;
  border: 3px solid #151515;
  padding-left: 20px;
  font: 900 28px "georgia";
  /* text-align: center; */
  word-spacing: 4px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-decoration:underline;
  background-color: #292b46;
}


.box1 {
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../images/banner-navratan-murabba.png");
  /* background-size: cover; */
  background-size: 100% 80%;
}

.box {
  /* background-position: right; */
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../images/Main_banner.jpeg");
  /* background-size: cover; */
  background-size: 100% 80%;
  color: #151515;
}

.box2 {
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../images/banner-spices.png");
  background-size: cover;
  background-size: 100% 80%;
}

.box3 {
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../images/oil-banner.jpg");
  background-size: 100% 80%;
}

.box4 {
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../images/dry-fruit-banner.jpg");
  background-size: 100% 80%;
}

.box5 {
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../images/pinnis-banner.cms");
  background-size: 100% 80%;
}

.box6 {
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../images/banner-gift-pack.jpg");
  background-size: 100% 80%;
}

.box7 {
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../../images/banner-other.webp");
  background-size: 100% 80%;
}















.contain{
  margin: 0;
  background-color: rgb(224, 219, 219);
  /* background-color: black; */
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-auto-rows: 300px;
  grid-template-rows: 700px ;
}

.box {
  /* background-color: rgb(0, 132, 255); */
  background-color: #292b46;
  color: white;
  /* border: 3px solid red; */
  /* height: 100vh; */
}

#section1 {
  margin-top: 20px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
}


#section2 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 7;
}

#section3 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 7;
  grid-row-end: 10;
  margin-bottom: -100px;
}
